import { Column, GridContainer, H2, Row } from '@vp/swan'
import ContactPreferencesForm from './ContactPreferencesForm'
import { EmailPreference } from './EmailPreferencesSwitch'
import { useLocalization } from '../../../../hooks/useLocalizations'

type ContactPreferencesProps = {
  userEmail: string;
  onCancel: () => void;
  onSaveClicked: (oldStatus: EmailPreference, newStatus: EmailPreference) => void;
  onSendFeedback: (content: string, isSuccess?: boolean) => void;
  onAltered: (altered: boolean) => void;
  storedPreference: EmailPreference;
  storedPreferenceError: boolean;
}

function ContactPreferencesUpdater (props: ContactPreferencesProps) {
  const { storedPreference, storedPreferenceError, onAltered, onSaveClicked, onSendFeedback, onCancel, userEmail } = props
  const { t } = useLocalization()
  return (
    <>
      <GridContainer>
        <Row>
          <Column span={12}>
            <H2 fontSkin='title-section'>{t('contactPreferences.title')}</H2>
          </Column>
        </Row>
      </GridContainer>
      <ContactPreferencesForm
        userEmail={userEmail}
        onCancel={onCancel}
        onSaveClicked={onSaveClicked}
        onSendFeedback={onSendFeedback}
        onAltered={onAltered}
        storedPreference={storedPreference}
        storedPreferenceError={storedPreferenceError}
      />
    </>
  )
}

const ContactPreferencesUpdaterWithTranslations = (props: ContactPreferencesProps) => {
  return (
    <ContactPreferencesUpdater {...props} />
  )
}

export default ContactPreferencesUpdaterWithTranslations
