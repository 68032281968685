import { useIdentity, useUserContext } from '@vp/ubik-context'
import { useEffect, useState } from 'react'
import CucosClient from '../infrastructure/views/components/ContactPreferences/clients/CucosClient'
import { EmailPreference } from '../infrastructure/views/components/ContactPreferences/EmailPreferencesSwitch'

const useStoredPreferences = (userEmail: string, delay: number) => {
  const { tenant, environment } = useUserContext()
  const { identity } = useIdentity()
  const cucosClient = new CucosClient(tenant || 'vistaprint', identity?.authorizationHeader || '', environment || 'prod')
  const [initialPreference, setInitialPreference] = useState<EmailPreference>('uninitialized')
  const [error, setError] = useState(false)

  useEffect(() => {
    const getInitialPreference = async () => {
      try {
        setInitialPreference(await cucosClient.getStoredPreference(userEmail))
      } catch (error: any) {
        if (error.response.status === 404) {
          setInitialPreference('opt-out')
        } else {
          setError(true)
        }
      }
    }
    getInitialPreference()
    const intervalId = setInterval(async () => {
      await getInitialPreference()
    }, delay)

    return () => {
      clearInterval(intervalId)
    }
  }, [JSON.stringify(identity), userEmail])

  return { initialPreference, error, setInitialPreference }
}

export default useStoredPreferences
